<template>
  <v-container>
    <BaseScreenHeader
      title="Manage Products"
      screenInfo="BIZ-002"
      :showBack="false"
    />

    <v-card-actions>
      <v-spacer />
      <v-select
        filled
        dark
        v-model="selectedBusiness"
        chips
        return-object
        prepend-inner-icon="mdi-office-building"
        :items="businessList"
        label="Business"
        outlined
        item-text="display_name"
        item-value="id"
        @change="loadProducts"
      />
    </v-card-actions>
    <v-card-actions class="py-0 pr-0">
      <v-spacer />
      <BaseActionButton
        label="Add"
        text
        color="silver"
        large
        title="Add New Product"
        icon="mdi-plus-circle-outline"
        @clickedThis="showAddProductDialog = true"
      />
    </v-card-actions>
    <v-card-actions class="pt-0 pb-4">
      <v-text-field
        filled
        dark
        class="enlarged-input pb-4 pt-0"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Search"
        single-line
        clearable
        hide-details
      />
    </v-card-actions>
    <v-row dense>
      <v-col cols="12" class="px-2">
        <v-data-iterator
          mobile-breakpoint="0"
          disable-sort
          :items-per-page="itemsPerPage"
          :items="productList"
          flat
          :page="page"
          :search="search"
          hide-default-footer
          :custom-filter="searchItems"
        >
          <template v-slot:default="props">
            <v-card
              elevation="0"
              :class="
                textSizeXSmall +
                  ' transparent charcoalTile silver--text link pa-2 pt-0 mb-4'
              "
              v-for="item in props.items"
              :key="item.name"
              @click="selectProduct(item)"
            >
              <v-card-actions :class="textSizeSmall + ' py-0 silver--text'">
                <v-divider />
                <span :class="statusColor(item.status) + ' mx-2'">
                  {{ item.name }}
                </span>
                <v-divider />
                <span :class="statusColor(item.status) + ' caption ml-2'">
                  {{ item.status }}
                </span>
              </v-card-actions>
              <v-card-actions
                class="pa-0"
                v-if="item.status == 'Draft' || item.status == 'Disabled'"
              >
                <v-spacer />
                <v-btn
                  v-if="item.status == 'Draft'"
                  plain
                  title="Publish Product"
                  class="orange--text"
                  @click.stop="publishItem(item)"
                  >Publish
                  <v-icon
                    class="orange--text"
                    title="Publish Product"
                    :large="!isPhone"
                  >
                    mdi-arrow-up-box
                  </v-icon>
                </v-btn>
                <v-btn
                  v-if="item.status == 'Disabled'"
                  class="silver--text"
                  plain
                  title="Re-Publish Product"
                  @click.stop="republishItem(item)"
                  >Re-Publish
                  <v-icon
                    class="silver--text"
                    title="Re-Publish Product"
                    :large="!isPhone"
                  >
                    mdi-arrow-up-box
                  </v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-actions class="pa-0 silver--text">
                {{ item.description }}
              </v-card-actions>
              <v-card-actions class="pa-0 silver--text">
                Package: {{ item.package }}
              </v-card-actions>
              <template v-if="item.external_prodid != ''">
                <v-card-title class="body-1 pa-0 ml-2 mt-2 mx-0 silver--text">
                  External Product Info
                </v-card-title>
                <v-card-actions
                  class="caption pa-0 mr-0 ml-2 mb-2 silver--text"
                >
                  ID: {{ item.external_prodid }}<br />
                  Test ID: {{ item.external_prodid_test }}<br />
                  Provider: {{ item.external_product_type }}
                </v-card-actions>
              </template>
              <v-card-actions class="caption silver--text px-0 py-0">
                <span title="Type"> Type: {{ item.type }} </span>
                <v-spacer />
                <span title="When">
                  {{ prettyDate(item.created) }}
                </span>
              </v-card-actions>
            </v-card>
          </template>
          <template v-slot:footer>
            <v-card-actions class="pt-0 pb-4 pl-4 pr-0 caption">
              <span class="grey--text">Per page</span>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn class="caption" text color="silver" v-on="on">
                    {{ itemsPerPage }}
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(number, index) in itemsPerPageArray"
                    :key="index"
                    @click="updateItemsPerPage(number)"
                  >
                    <v-list-item-title>{{ number }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer />
              <div class="pa-0 mx-0">
                Page {{ page }} of {{ numberOfPages }}
                <v-btn fab text x-small @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn fab text x-small @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-card-actions>
          </template>
          <template v-slot:no-data>
            <v-card-text class="py-10 my-10 body-1 paper--text text--darken-1">
              Sorry, we found no matching products.
            </v-card-text>
          </template>
          <template v-slot:no-results>
            <v-card-text class="py-10 my-10 body-1 paper--text text--darken-1">
              Sorry, we found no products matching this criteria.
            </v-card-text>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showRowInfo"
      :fullscreen="isPhone"
      width="900"
      overlay-opacity=".95"
    >
      <v-card tile class="noscroll-x">
        <v-sheet color="lightHeader" class="silver--text">
          <v-card-title class="title">
            Manage Product
            <v-spacer />
            <v-icon color="silver" large @click="cancel" title="Close">
              mdi-close
            </v-icon>
          </v-card-title>
        </v-sheet>
        <v-list class="pl-0" dense>
          <v-subheader :class="textSizeSmall">
            Product Variations
            <v-spacer />
            <BaseActionButton
              label="Add Variation"
              plain
              :large="!isPhone"
              title="Add Product Variation"
              icon="mdi-plus-circle-outline"
              @clickedThis="addVariation"
            />
          </v-subheader>
          <v-list-item
            v-for="(variation, index) in productVariations"
            :key="index"
            @click="editVariation(variation)"
            class="caption silver--text"
          >
            <v-list-item-icon class="mr-1 mt-3">
              <v-icon>{{
                selectedVariation == null ||
                variation.id != selectedVariation.id
                  ? 'mdi-checkbox-blank-outline'
                  : 'mdi-checkbox-marked-outline'
              }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-0">
              <v-card flat class="px-0 py-1">
                <v-card-text class="caption py-0 px-0">
                  {{ index + 1 }}. {{ variation.sku }} - {{ variation.price }}
                  {{ variation.currency }}
                </v-card-text>
                <v-card-text class="pa-0">{{
                  variation.description
                }}</v-card-text>
                <v-card-text class="pa-0">
                  Recurrence: {{ variation.interval }}
                  {{ variation.recurrence }}(s)
                </v-card-text>
              </v-card>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon
                title="Delete Variation"
                @click="showDeleteVariation(variation)"
                class="accent--text"
              >
                mdi-trash-can-outline
              </v-icon>
            </v-list-item-icon>
            <v-list-item-icon>
              <v-icon title="Edit Variation" @click="editVariation(variation)">
                mdi-pencil
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <template v-if="selectedVariation == null">
          <v-card-actions class="mb-2">
            <v-divider />
            Product information
            <v-divider />
          </v-card-actions>
          <v-row dense class="px-2">
            <v-col cols="12">
              <v-text-field
                v-model="selectedProduct.name"
                ref="name"
                :counter="50"
                label="Name"
                prepend-icon="mdi-account"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="selectedProduct.type"
                chips
                prepend-icon="mdi-view-list"
                :items="productTypes"
                label="Product Type"
                outlined
                item-text="name"
                item-value="name"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                disabled
                v-model="selectedProduct.status"
                chips
                prepend-icon="mdi-view-list"
                :items="productStatuses"
                label="Product Status"
                outlined
                item-text="name"
                item-value="name"
                :persistent-hint="true"
                :hint="showHint(selectedProduct.status)"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="selectedProduct.description"
                ref="description"
                :counter="400"
                :rows="3"
                label="Description"
                prepend-icon="mdi-text"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedProduct.statement_descriptor"
                ref="statementDescriptor"
                :counter="20"
                label="Statement Descriptor"
                prepend-icon="mdi-package-variant"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedProduct.package"
                ref="package"
                :counter="20"
                label="Package"
                prepend-icon="mdi-package-variant"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedProduct.priority"
                ref="priority"
                :counter="3"
                label="Priority"
                type="number"
                prepend-icon="mdi-priority-high"
              />
            </v-col>
          </v-row>
          <v-card-actions class="pt-0">
            <v-spacer />
            <v-checkbox
              :color="showExternal ? 'silver' : 'accent'"
              class="pl-3 enlarged-checkbox"
              off-icon="mdi-chevron-down"
              on-icon="mdi-chevron-up"
              v-model="showExternal"
              :label="showExternal ? 'Hide External' : 'Show External'"
            />
          </v-card-actions>
          <v-row dense v-if="showExternal" class="px-2">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedProduct.external_prodid"
                ref="externalprodid"
                :counter="50"
                label="External Product ID"
                prepend-icon="mdi-link-box-outline"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedProduct.external_prodid_test"
                ref="externalprodidtest"
                :counter="50"
                label="External Product ID Test"
                prepend-icon="mdi-link-box-outline"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedProduct.external_prodid_blank"
                ref="externalprodidblank"
                :counter="50"
                label="External Product ID Blank"
                prepend-icon="mdi-link-box-outline"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedProduct.external_product_type"
                ref="externalprodtype"
                :counter="20"
                label="External Product Type"
                prepend-icon="mdi-link-box-outline"
              />
            </v-col>
          </v-row>
          <v-card-subtitle :class="textSizeXSmall + ' pt-2'">
            Review the information above. Click
            <span class="accent--text">Disable</span> to disable a product.
            <br />
            Click <span class="accent--text">Save</span> to save all other
            changes.
          </v-card-subtitle>
          <v-spacer />
          <v-card-actions class="px-0">
            <BaseActionButton
              plain
              :large="!isPhone"
              label="Disable"
              icon="mdi-trash-can-outline"
              @clickedThis="disableProduct"
            />
            <v-spacer />
            <BaseActionButton
              plain
              :large="!isPhone"
              label="Save"
              @clickedThis="saveProduct"
            />
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-actions class="mb-2 accent--text">
            <v-divider />
            Variation Information
            <v-divider />
          </v-card-actions>
          <v-row dense class="px-2">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedVariation.sku"
                ref="sku"
                :counter="50"
                label="SKU"
                prepend-icon="mdi-account"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="selectedVariation.description"
                ref="description"
                :counter="400"
                :rows="3"
                label="Description"
                prepend-icon="mdi-text"
              />
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedVariation.grace"
                ref="grace"
                :counter="3"
                label="Grace Period (days)"
                type="number"
                prepend-icon="mdi-send-clock"
              />
            </v-col>
          </v-row>
          <v-row dense class="px-2">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedVariation.value"
                ref="value"
                :counter="100"
                label="Value"
                prepend-icon="mdi-text-recognition"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="selectedVariation.value_details"
                ref="valueDetails"
                :counter="100"
                label="Value Details"
                prepend-icon="mdi-text-recognition"
              />
            </v-col>
            <v-col :cols="selectedVariation.use_stock == 'Yes' ? 4 : 12">
              <v-checkbox
                :color="selectedVariation.use_stock ? 'silver' : 'accent'"
                class="enlarged-checkbox"
                true-value="Yes"
                false-value="No"
                v-model="selectedVariation.use_stock"
                label="Use Stock"
              />
            </v-col>
            <template v-if="selectedVariation.use_stock == 'Yes'">
              <v-col cols="4">
                <v-text-field
                  v-model="selectedVariation.stock"
                  ref="stock"
                  :counter="5"
                  type="number"
                  label="Stock"
                  prepend-icon="mdi-link-box-outline"
                />
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="selectedVariation.limit_qty"
                  ref="limitqty"
                  :counter="5"
                  label="Limit Quantity"
                  type="number"
                  prepend-icon="mdi-link-box-outline"
                />
              </v-col>
            </template>
          </v-row>
          <v-card-actions class="mb-2 accent--text">
            <v-divider />
            Price Information
            <v-divider />
          </v-card-actions>
          <v-card-text>
            Price information for an existing variation(subscription) can not be
            changed. To update the information in this section you need to
            create a new variation and deactivate the existing one.
          </v-card-text>
          <v-row dense class="px-2">
            <v-col cols="12" md="6">
              <v-text-field
                disabled
                v-model="selectedVariation.price"
                ref="price"
                :counter="10"
                label="Price"
                type="number"
                prepend-icon="mdi-cash"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                disabled
                v-model="selectedVariation.currency"
                ref="currency"
                :counter="5"
                label="Currency"
                prepend-icon="mdi-currency-usd"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                disabled
                v-model="selectedVariation.external_priceid"
                ref="externalpriceid"
                :counter="50"
                label="External Price ID"
                prepend-icon="mdi-link-box-outline"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                disabled
                v-model="selectedVariation.external_priceid_test"
                ref="externalpriceidtest"
                :counter="50"
                label="External Price ID Test"
                prepend-icon="mdi-link-box-outline"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                disabled
                v-model="selectedVariation.recurrence"
                ref="recurrence"
                :counter="20"
                label="recurrence"
                prepend-icon="mdi-autorenew"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                disabled
                v-model="selectedVariation.interval"
                ref="priority"
                :counter="3"
                label="Interval"
                type="number"
                prepend-icon="mdi-clock-start"
              />
            </v-col>
          </v-row>
          <v-card-subtitle :class="textSizeXSmall + ' pt-2'">
            Review the information above. Click
            <span class="accent--text">Save</span> to save the product variation
            changes.
          </v-card-subtitle>
          <v-spacer />
          <v-card-actions class="px-0">
            <BaseActionButton
              plain
              :large="!isPhone"
              label="Back"
              icon="mdi-arrow-left"
              color="info"
              @clickedThis="backVariation"
            />
            <v-spacer />
            <BaseActionButton
              plain
              :large="!isPhone"
              label="Save Variation"
              @clickedThis="saveVariation"
            />
          </v-card-actions>
        </template>
      </v-card>
      <BaseSnackBar :show="snackbar" :snack="snackText" />
    </v-dialog>
    <v-dialog v-model="deleteVariationDialog" persistent width="500">
      <v-card align="stretch" elevation="20">
        <v-sheet color="lightHeader" class="silver--text" height="60">
          <v-card-title class="title"> Delete Variation? </v-card-title>
        </v-sheet>
        <v-card-title
          v-if="selectedForDelete != null"
          :class="textSizeXSmall + ' accent--text'"
        >
          {{ selectedForDelete.sku }}
        </v-card-title>
        <v-card-subtitle style="min-height: 200px" class="body-1 py-4">
          Are you sure you want to delete this variation?
        </v-card-subtitle>
        <v-divider />
        <v-card-actions>
          <BaseActionButton
            icon="mdi-close"
            color="info"
            label="Cancel"
            :large="!isPhone"
            plain
            @clickedThis="cancelDeleteVariation"
          />
          <v-spacer />
          <BaseActionButton
            icon="mdi-delete-circle-outline"
            label="Yes, Delete"
            :large="!isPhone"
            plain
            @clickedThis="deleteVariation(selectedForDelete)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showPublishDialog" persistent width="500">
      <v-card align="stretch" elevation="20">
        <v-sheet color="lightHeader" class="silver--text" height="60">
          <v-card-title class="title">
            <span class="mr-2" v-if="republish">Re-publish</span>
            <span class="mr-2" v-else>Publish</span> Product?
          </v-card-title>
        </v-sheet>
        <v-card-title
          v-if="selectedForPublish != null"
          :class="textSizeXSmall + ' accent--text'"
        >
          {{ selectedForPublish.name }}
        </v-card-title>
        <v-card-subtitle style="min-height: 200px" class="body-1 py-4">
          Are you sure you want to <span v-if="republish">re-publish</span>
          <span v-else>publish</span>
          this product?
        </v-card-subtitle>
        <v-divider />
        <v-card-actions>
          <BaseActionButton
            icon="mdi-close"
            color="info"
            label="Cancel"
            :large="!isPhone"
            plain
            @clickedThis="cancelPublish"
          />
          <v-spacer />
          <BaseActionButton
            v-if="republish"
            icon="mdi-arrow-up-box"
            label="Yes, Republish"
            :large="!isPhone"
            plain
            @clickedThis="republishProduct(selectedForPublish)"
          />
          <BaseActionButton
            v-else
            icon="mdi-arrow-up-box"
            label="Yes, Publish"
            :large="!isPhone"
            plain
            @clickedThis="publishProduct(selectedForPublish)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoadingDialog
      :show="!loaded"
      title="LOADING Business Products..."
      color="accent"
    />
    <AddProductVariation
      :show="showAddProductVariationDialog"
      :product="selectedProduct"
      :businessid="selectedBusiness.id"
      @cancel="showAddProductVariationDialog = false"
      @done="variationAdded"
    />
    <AddProduct
      :show="showAddProductDialog"
      :productTypes="productTypes"
      :businessid="selectedBusiness.id"
      @cancel="showAddProductDialog = false"
      @done="productAdded"
    />
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

const LoadingDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/Loading.vue')
const AddProductVariation = () =>
  import(
    /* webpackPrefetch: true */ '@/components/dialogs/AddProductVariation.vue'
  )
const AddProduct = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/AddProduct.vue')
export default {
  components: { LoadingDialog, AddProductVariation, AddProduct },
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      textFieldProps: {
        appendIcon: 'mdi-calendar'
      },
      dateProps: {
        headerColor: 'charcoal',
        color: 'progressActive'
      },
      timeProps: {
        scrollable: true,
        headerColor: 'charcoal',
        color: 'progressActive',
        useSeconds: false,
        ampmInTitle: true
      },
      loaded: false,
      showExternal: false,
      showEditVariation: false,
      saveWeight: false,
      selectedProduct: {},
      selectedVariation: null,
      selectedForDelete: null,
      selectedForPublish: null,
      productType: {},
      selectedBusiness: {},
      showAddProductDialog: false,
      showAddProductVariationDialog: false,
      deleteVariationDialog: false,
      showPublishDialog: false,
      showRowInfo: false,
      republish: false,
      activity: '',
      added: '',
      search: '',
      productList: [],
      productTypes: [],
      productStatuses: [],
      productVariations: [],
      businessList: [],
      datetime: null,
      selectedDateTime: null,
      itemsPerPageArray: [20, 50, 100, 500],
      itemsPerPage: 50,
      page: 1,
      snackbar: false,
      snackText: '',
      timeout: 1000
    }
  },
  beforeMount() {
    this.loadBusinesses()
    this.loadProductTypes()
    this.loadProductStatuses()
  },
  computed: {
    itemsCount() {
      return this.productList.length
    },
    numberOfPages() {
      return Math.ceil(this.productList.length / this.itemsPerPage)
    },
    ...appConfig
  },
  methods: {
    variationAdded() {
      this.showAddProductVariationDialog = false
      this.loadProductVariations()
      this.selectedVariation = null
    },
    productAdded() {
      this.showAddProductDialog = false
      this.loadProducts()
    },
    cancelPublish() {
      this.showPublishDialog = false
      this.selectedForPublish = null
    },
    cancelDeleteVariation() {
      this.deleteVariationDialog = false
      this.selectedForDelete = null
    },
    showDeleteVariation(variation) {
      this.deleteVariationDialog = true
      this.selectedForDelete = variation
    },
    publishItem(item) {
      this.showPublishDialog = true
      this.selectedForPublish = item
      this.republish = false
    },
    republishItem(item) {
      this.showPublishDialog = true
      this.selectedForPublish = item
      this.republish = true
    },
    showHint(status) {
      if (status == 'Draft')
        return 'Draft Products can be published via the product list only'
      if (status == 'Published')
        return 'Published Products can only be disabled using the Disable button.'
      if (status == 'Disabled')
        return 'Disabled Products can only be Re-activated suing the Re-Activate button.'
      if (status == 'Local')
        return 'Local Products can not change status. Create another product'
    },
    statusColor(status) {
      if (status == 'Disabled') return 'silver--text'
      else if (status == 'Local') return 'paper--text'
      else if (status == 'Draft') return 'orange--text'
      else return 'progressActive--text'
    },
    searchItems(items, search) {
      if (this.isEmpty(search) || search.length <= 2) return items
      let searchTerm = search.replace(' ', '').toLowerCase()

      let result = items.filter(function(item) {
        let name = item.name.replace(' ', '')
        name = name.toLowerCase()

        return name.includes(searchTerm)
      })
      return result
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    saved() {
      this.loadProducts()
      this.showAddProductDialog = false
    },
    addProduct() {
      this.showAddDialog = true
    },
    addVariation() {
      this.showAddProductVariationDialog = true
    },
    cancel() {
      this.showAddDialog = false
      this.showRowInfo = false
      this.selectedVariation = null
    },
    backVariation() {
      this.selectedVariation = null
    },
    selectProduct(value) {
      this.showRowInfo = true
      this.selectedProduct = value
      this.loadProductVariations()
    },
    editVariation(variation) {
      this.showEditVariation = true
      this.selectedVariation = variation
    },
    disableProduct() {
      return axios
        .put(this.baseURL + '/product/disable', {
          product: this.selectedProduct,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.loadProducts()
            this.showRowInfo = false
            this.selectedVariation = null
            this.toast('Product disabled succesfully..')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    deleteVariation(variation) {
      return axios
        .put(this.baseURL + '/product/variation/disable', {
          variation: variation,
          businessid: this.selectedBusiness.id,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.loadProductVariations()
            this.deleteVariationDialog = false
            this.selectedVariation = null
            this.toast('Product variation deleted succesfully..')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveVariation() {
      return axios
        .put(this.baseURL + '/product/variation', {
          variation: this.selectedVariation,
          businessid: this.selectedBusiness.id
        })
        .then(response => {
          if (response.status == 200) {
            this.selectedVariation = null
            this.showEditVariation = false
            this.loadProductVariations()
            this.switchNum++
            this.toast('Product variation succesfully saved..')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    saveProduct() {
      return axios
        .put(this.baseURL + '/product/save', {
          product: this.selectedProduct,
          businessid: this.selectedBusiness.id
        })
        .then(response => {
          if (response.status == 200) {
            this.selectedProduct = {}
            this.showRowInfo = false
            this.loadProducts()
            this.switchNum++
            this.$store.dispatch(
              'notification/addNotification',
              'Product was succesfully saved.',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    publishProduct(product) {
      return axios
        .put(this.baseURL + '/product/publish', {
          product: product,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.showPublishDialog = false
            this.selectedForPublish = null
            this.loadProducts()
            this.switchNum++
            this.$store.dispatch(
              'notification/addNotification',
              'Product was succesfully published.',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    republishProduct(product) {
      return axios
        .put(this.baseURL + '/product/republish', {
          product: product,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.showPublishDialog = false
            this.selectedForPublish = null
            this.loadProducts()
            this.switchNum++
            this.$store.dispatch(
              'notification/addNotification',
              'Product was succesfully re-published.',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadProducts() {
      return axios
        .get(
          this.baseURL +
            '/business/products/' +
            this.selectedBusiness.id +
            '/parent',
          {}
        )
        .then(response => {
          if (response.status == 200) {
            this.productList = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadProductTypes() {
      return axios.get(this.baseURL + '/product/types', {}).then(response => {
        if (response.status == 200) {
          this.productTypes = response.data.data
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    },
    loadProductStatuses() {
      return axios
        .get(this.baseURL + '/product/statuses', {})
        .then(response => {
          if (response.status == 200) {
            this.productStatuses = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadProductVariations() {
      return axios
        .get(
          this.baseURL + '/product/variations/' + this.selectedProduct.id,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            this.productVariations = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadBusinesses() {
      return axios.get(this.baseURL + '/business/all', {}).then(response => {
        if (response.status == 200) {
          this.businessList = response.data.data
          this.selectedBusiness = this.businessList[0]
          this.loadProducts()
          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    }
  }
}
</script>
